/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
								
				/* WOW.JS / Animate.css */
				// Fade on page exit.
				/* Works for Firefox & Chrome */
				window.addEventListener('beforeunload', function () {
					$('#body-wrap').addClass('fadeOut');
				});
				/* ------ */
							
							
				/* Home Carousel - Full Screen */
				function setHomeCarouselscreen() {
					var windowHeight = $(window).innerHeight();
					var headerHeight = $('header#header').innerHeight();
					$('#homeCarousel .cover-image').css('min-height', windowHeight - headerHeight - 100);
				}
				setHomeCarouselscreen();
				$(window).resize(function() {
					setHomeCarouselscreen();
				});
				/* ------ */
							
								
				/* FULLSCREEN HEIGHT */
				function setFullscreen() {
					var windowHeight = $(window).innerHeight();
					$('.fullscreen').css('min-height', windowHeight);
				}
				setFullscreen();
				$(window).resize(function() {
					setFullscreen();
				});
				/* ------ */
								
								
				/* Smooth-Scroll */
        		$('[data-target]').click(function(e) {
					e.preventDefault();
					var target = $(this).attr('data-target');
					$('html, body').animate({scrollTop: $(target).offset().top - 60}, 600);
				});
				/* ------ */
        
        
        		/* Click-Element */
        		$('div[href], tr[href], p[href], li[href]').click(function() {
          			window.location.href=$(this).attr('href');
        		});
				/* ------ */				
								
								
				/* HEADER STICKY CONSTANT */
				/* affix the navbar on scroll */
				var header = $('.header-wrapper'),
				navbar = $('#sticky-navigation'),
				$window = $(window);
				
				$window.scroll(function() {
					if($window.scrollTop() > 350) {
						navbar.addClass('navbar-fixed-top').addClass('slideInDown').removeClass('slideOutUp');
					}
					else if($window.scrollTop() > 100 && $window.scrollTop() < 349){
						navbar.removeClass('slideInDown').addClass('slideOutUp');
					}
					else {
						navbar.removeClass('navbar-fixed-top slideInDown slideOutUp');
					} 
				});
								
				function setHeaderOffset() {
					var headerHeight = header.innerHeight();
					$('#body-wrap').css('top', headerHeight);
				}
				setHeaderOffset();
				$(window).resize(function() {
					setHeaderOffset();
				});
				/* ------ */
				
				/* Slick Featured News */
				$('.feature-children-slick').slick({
					vertical: false,
					rows: 1,
					arrows: true,
					dots: false,
					infinite: false,
					speed: 1000,
					slidesToShow: 4,
					slidesToScroll: 1,
					swipe: true,
					swipeToSlide: true,
					lazyLoad: 'ondemand',
					responsive: [{
						breakpoint: 992,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1,
							arrows: false,
							dots: true
						}
					},{
						breakpoint: 762,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							arrows: false,
							dots: true,
							cssEase: 'easeOutElastic', //'linear',
						}
					}]
					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				});
				/* ------ */
				
				
			},
      		finalize: function() {
        		// JavaScript to be fired on all pages, after page specific JS is fired
      		}
    	},
    	// Home page
    	'home': {
      		init: function() {
        		// JavaScript to be fired on the home page
								
								
				/* Home Carousel */
				$('.feature-video-play').click(function () {
					$('#homeCarousel').carousel('pause');
				});
				/* ------ */
								
				/* Bootstrap Carousel Responsive Slide Visibility 	*/
				var $window = $(window);
				function deviceWidth() {
					return $window.width();
				} 
							
				function toggleMobileSlideVisibility(show_hide) {
					$no_mobile_slides = $('.carousel-inner').find('.no-mobile');
					if (show_hide === 'hide'){
						var reset_active_slide = false;
						$no_mobile_slides.each(function(i,e){
							if ($(e).hasClass('active')) {
								reset_active_slide = true;
								$(e).removeClass('active');
							}
						});
						$no_mobile_slides.removeClass('item').addClass('hide');
						if (reset_active_slide) {
							$('.carousel-inner').find('.item').first().addClass('active');
						}
						} else if (show_hide === 'show') {
							$no_mobile_slides.addClass('item').removeClass('hide');
						}
					}
								
					var is_mobile_device = false;
					var detectMobile = function detectMobile(){
						if (deviceWidth() > 978) {
							if (is_mobile_device) { toggleMobileSlideVisibility('show'); }
								is_mobile_device = false;
							} else {
								if (!is_mobile_device) { toggleMobileSlideVisibility('hide'); }
								is_mobile_device = true;
							}
						};
							
						$(window).on('resize', function(){
							detectMobile();
						});
							
						detectMobile();
					/* ------ */
								
								
      			},
				finalize: function() {
        			// JavaScript to be fired on the home page, after the init JS
								
					/* Feature Products - Carousel/Slider
					$('#feature-grid').carouFredSel({
						auto: {
							pauseOnHover: 'resume'
						},
						items: {
							width: null,
							height: null,
							visible: {
								min: 1,
								max: 4
							}
						},
						direction: "left",
						scroll: {
							items: 1,
							duration: 1000,
							pauseOnHover: true
						},
						prev: '#feature-grid-prev',
						next: '#feature-grid-next',
						pagination: '#feature-grid-pager',
						responsive: true,
						swipe: {
							onTouch: true
						},
						width: "100%",
						height: null
					});
					/* ------ */
								
								
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.